import { SectionTitle } from "./common/SectionTitle";
import blogImage1 from "./assets/images/blog-image-1.png";
import blogImage2 from "./assets/images/marketplace.jpg";
import blogImage3 from "./assets/images/blog-image-3.jpeg";
import blogImage4 from "./assets/images/blog-image-4.jpeg";
import blogImage5 from "./assets/images/blog-image-5.jpeg";
import blogImage6 from "./assets/images/blog-image-6.png";

import Image from "next/image";
// @ts-ignore
import Bounce from "react-reveal/Bounce";
import { ExternalLinkIcon } from "@heroicons/react/solid";

const posts = [
  {
    title: "What is $MAX token?And how does Maxima platform utilise it?",
    href: "https://maximahorse.medium.com/what-is-max-token-and-how-does-maxima-platform-utilise-it-73edc75bf409",
    category: {
      name: "Medium",
      href: "https://maximahorse.medium.com",
    },
    description:
      "Maxima is an innovative platform designed to host all functions of the existing horse-racing industry. It also opens up many opportunities and niches through emerging crypto applications such as NFTs and DeFi.",
    date: "Feb 9, 2022",
    datetime: "2022-02-09",
    imageUrl: blogImage5,
    readingTime: "4 min",
    author: {
      name: "Daniela Metz",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: "Horse Racing after the PandemicHorse Racing after the Pandemic",
    href: "https://maximahorse.medium.com/horse-racing-after-the-pandemic-fa96c0ed6ff",
    category: {
      name: "Medium",
      href: "https://maximahorse.medium.com",
    },
    description:
      "The Pandemic has changed many things, but one that changed in a way that has led to more excitement rather than disappointment has been horse racing, and specifically horse auctions.",
    date: "Jan 21, 2022",
    datetime: "2022-01-21",
    imageUrl: blogImage4,
    readingTime: "3 min",
    author: {
      name: "Daniela Metz",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: "Realworld Racehorse Token $MAX: Own, Race, Earn, Repeat",
    href: "https://bitrss.com/news/226285/maxima-horse-syndication-token-own-race-earn-repeat",
    category: {
      name: "BitRss",
      href: "https://bitrss.com/",
    },
    description:
      "As always in a reality that aims to be the link between reality and virtual, the focus of the question is usually reduced to a few questions; one of them is obviously ... what is the purpose.",
    date: "Oct 31, 2021",
    datetime: "2021-10-31",
    imageUrl: blogImage6,
    readingTime: "5 min",
    author: {
      name: "Daniela Metz",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: "How To Be Part of Maxima?",
    href: "https://maximahorse.medium.com/how-to-be-part-of-maxima-d37843bf258",
    category: {
      name: "Medium",
      href: "https://maximahorse.medium.com",
    },
    description:
      "Thanks to inventions like the blockchain and NFTs, Maxima has identified a reliable and fair way of democratizing racehorse ownership. With these two inventions, it is now possible for anyone interested to own a racehorse without paying the thousands of dollars that these horses currently cost.",
    date: "Oct 26, 2021",
    datetime: "2021-10-26",
    imageUrl: blogImage3,
    readingTime: "9 min",
    author: {
      name: "Brenna Goyette",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title:
      "Maxima Launches the First Racehorse NFT Marketplace Where Anyone Can Own Horses And Earn",
    href: "https://finance.yahoo.com/news/maxima-launches-first-racehorse-nft-162500184.html",
    category: {
      name: "Yahoo! Finance",
      href: "https://finance.yahoo.com/news",
    },
    description:
      "Maxima has announced the launch of the world’s first blockchain-based racehorse ownership exchange powered by NFTs. Unlike other NFT marketplaces, Maxima is built for tokenizing real world assets.",
    date: "Oct 24, 2021",
    datetime: "2021-10-24",
    imageUrl: blogImage2,
    readingTime: "8 min",
    author: {
      name: "Roel Aufderehar",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: "Maxima: Bridging NFT & Horse Racing",
    href: "https://maximahorse.medium.com/maxima-bridging-ntf-horse-racing-a19029aa92ae",
    category: {
      name: "Medium",
      href: "https://maximahorse.medium.com",
    },
    description:
      "Maxima is ushering in a new era of innovation in equestrian performance sports with its NFT offerings. The platform aims to build a blockchain-powered ecosystem for racehorse ownership through its NFT integration and trading exchange services.",
    date: "Oct 22, 2021",
    datetime: "2021-10-22",
    imageUrl: blogImage1,
    readingTime: "3 min",
    author: {
      name: "Daniela Metz",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
];

export default function Blog() {
  return (
    <section className="anchor-blog">
      <div className="relative px-4 pt-16 pb-20 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="relative mx-auto max-w-7xl">
          <div className="text-center">
            <SectionTitle subText="Recent publications" isSubTextAbove>
              From the Media
            </SectionTitle>
          </div>
          <div className="grid max-w-lg gap-5 mx-auto mt-12 lg:grid-cols-3 lg:max-w-none">
            {posts.map((post) => (
              <Bounce key={post.title}>
                <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                  <div className="flex-shrink-0">
                    <Image
                      className="object-cover w-full h-48"
                      src={post.imageUrl}
                      alt="From the media"
                      placeholder="blur"
                    />
                  </div>
                  <div className="flex flex-col justify-between flex-1 p-6 bg-gray-200">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-indigo-600">
                        <a
                          href={post.category.href}
                          className="hover:underline"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {post.category.name}
                        </a>
                      </p>
                      <a
                        href={post.href}
                        target="_blank"
                        className="block mt-2"
                        rel="noreferrer"
                      >
                        <p className="text-xl font-semibold text-gray-900">
                          {post.title}
                        </p>
                        <p className="mt-3 text-base text-gray-500">
                          {post.description}
                        </p>
                      </a>
                    </div>
                    <div className="flex items-center mt-6">
                      <div className="flex space-x-1 text-sm text-gray-500">
                        {/* <time dateTime={post.datetime}>{post.date}</time>
                        <span aria-hidden="true">&middot;</span> */}
                        <span>{post.readingTime} read</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Bounce>
            ))}
          </div>
        </div>

        <div className="flex items-center justify-center mt-8">
          <div className="inline-flex rounded-md shadow">
            <a
              href="https://maximahorse.medium.com"
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium border rounded-md text-maxima-secondary-200 border-maxima-secondary-200 hover:border-green-200 hover:text-green-200"
            >
              View more on Medium
              <ExternalLinkIcon
                className="w-5 h-5 ml-3 -mr-1 text-maxima-secondary-200"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
